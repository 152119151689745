import React from "react";
import PropTypes from "prop-types";

function Badge({ color, className, children }) {
  let classList = ["shared__badge", `shared__badge--${color}`];

  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );
  return (
    <div role="status" className={classList.join(" ")}>
      <span className="shared__badge-text">{children}</span>
    </div>
  );
}

Badge.designSystemProps = {
  color: {
    type: "string",
    description: "Badge color.",
    default: "lightEucalyptus",
    propType: PropTypes.string,
    required: false,
  },
  children: {
    type: "node",
    description: "Badge text.",
    default: "New",
    propType: PropTypes.node,
    required: false,
  },
  className: {
    type: ["string", "array"],
    description: "List of classes for this element.",
    required: false,
    default: "",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(Badge.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
Badge.propTypes = { ...propTypes };
Badge.defaultProps = { ...defaultProps };

Badge.displayName = "Badge";

export default Badge;
