import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import Badge from "../components/atoms/badge";

function DesignBadge() {
  return (
    <div className="design__content">
      <SEO title="Design System - Badges" />
      <h2>Badges</h2>

      <div className="design__stories">
        <div className="design__story-section">
          {/* <h5>Badges</h5> */}
          <DesignStory
            type="component"
            component={Badge}
            className="shared__badge--spacing"
            compact
            props={{
              color: "lightEucalyptus",
            }}>
            New
          </DesignStory>
          <DesignStory
            type="component"
            component={Badge}
            className="shared__badge--spacing"
            compact
            props={{
              color: "buffyOrange",
            }}>
            New
          </DesignStory>
          <DesignStory
            type="component"
            component={Badge}
            className="shared__badge--spacing"
            compact
            props={{
              color: "purple",
            }}>
            New
          </DesignStory>
          <DesignStory
            type="component"
            component={Badge}
            className="shared__badge--spacing"
            compact
            props={{
              color: "orange",
            }}>
            New
          </DesignStory>
          <DesignStory
            type="component"
            component={Badge}
            className="shared__badge--spacing"
            compact
            props={{
              color: "yellow",
            }}>
            New
          </DesignStory>
          <DesignStory
            type="component"
            component={Badge}
            className="shared__badge--spacing"
            compact
            props={{
              color: "green",
            }}>
            New
          </DesignStory>
          <DesignStory
            type="component"
            component={Badge}
            className="shared__badge--spacing"
            compact
            props={{
              color: "blue",
            }}>
            New
          </DesignStory>
        </div>

        <div className="design__story-section">
          <DesignProps component={Badge} title="Badge Properties" />
        </div>
      </div>
    </div>
  );
}

export default DesignBadge;
